<template>
    <div class="unsubscribe">
        <div class="container-fluid">
            <div class="row">
                <div class="container">
                    <card style="box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row">
                                    <template v-if="state === 'unsubscribedType'">
                                        <div class="col-lg-12">
                                            <div class="face-container" style="background-image: url('/images/settings/unsubscribe/goodbye.png')"></div>
                                        </div>

                                        <div class="col-lg-12" style="text-align: center;">
                                            <p id="intro-tekst">{{$t('overview.onboarding.onboardingUnsubscribe.successUnsubscribeType')}}</p>
                                        </div>
                                    </template>

                                    <template v-else-if="state === 'unsubscribedGroup'">
                                        <div class="col-lg-12">
                                            <div class="face-container" style="background-image: url('/images/settings/unsubscribe/goodbye.png')"></div>
                                        </div>

                                        <div class="col-lg-12" style="text-align: center;">
                                            <p id="intro-tekst">{{$t('overview.onboarding.onboardingUnsubscribe.successUnsubscribeType')}}</p>
                                        </div>
                                    </template>

                                    <template v-else-if="state === 'unsubscribedAll'">
                                        <div class="col-lg-12">
                                            <div class="face-container" style="background-image: url('/images/settings/unsubscribe/goodbye.png')"></div>
                                        </div>

                                        <div class="col-lg-12" style="text-align: center;">
                                            <p id="intro-tekst">{{$t('overview.onboarding.onboardingUnsubscribe.successUnsubscribeAll')}}</p>
                                        </div>
                                    </template>

                                    <template v-else>
                                        <div class="col-lg-12">
                                            <div class="face-container" :style="{backgroundPosition: face}"></div>
                                        </div>

                                        <div class="col-lg-12" style="text-align: center;">
                                            <h4 id="intro-tekst">{{$t('overview.onboarding.onboardingUnsubscribe.headline')}}</h4>
                                            <p>{{$t('overview.onboarding.onboardingUnsubscribe.description')}}</p>

                                            <div v-if="state === 'error'"
                                                 class="alert alert-danger"
                                                 v-html="$t('overview.onboarding.onboardingUnsubscribe.woops', { phoneNumber: $t('phoneNumber'), mail: $t('mail')})">
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-lg-offset-3" v-if="notification && notificationGroup" style="margin-bottom: 20px;">
                                            <ul>
                                                <li class="notification-type">
                                                    <input type="radio" class="clickable" value="type" v-model="selectedUnsubscriptionLevel" />
                                                    <span v-html="$t(`profile.notificationCenter.${notificationGroup.slug}.${notification.slug}`)" />
                                                </li>
                                                <li class="notification-type">
                                                    <input type="radio" class="clickable" value="group" v-model="selectedUnsubscriptionLevel" />
                                                    <span v-html="$t(`profile.notificationCenter.${notificationGroup.slug}.title`)" />
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="col-lg-4 col-lg-offset-4" style="text-align: center;">
                                            <span class="general-purpose-button unsubscribe-button"
                                                  @mouseover="setHoverButton('sad')"
                                                  @mouseleave="setHoverButton(null)"
                                                  @click="unsubscribe"
                                                  :class="{ error: state === 'error' }">
                                                <span v-if="state === 'unsubscribing'" class="fa fa-circle-o-notch fa-spin fa-fw"/>
                                                {{$t('overview.onboarding.onboardingUnsubscribe.negativeButton')}}
                                            </span>

                                                <span class="general-purpose-button regret-button"
                                                      @click="cancel"
                                                      @mouseover="setHoverButton('happy')"
                                                      @mouseleave="setHoverButton(null)">
                                                {{$t('overview.onboarding.onboardingUnsubscribe.positiveButton')}}
                                            </span>
                                        </div>
                                    </template>

                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .notification-type {
        padding: 5px 10px;
        border-radius: 3px;
        background: #f9f9f9;
        margin-bottom: 5px;
    }

    .error {
        opacity: 0.4;
        cursor: not-allowed !important;
    }

    ul {
        list-style-type: none;
        padding-left: 20px;
        li {
            font-size: 12px;
            .clickable {
                margin-right: 10px;
                display: inline-block;
            }
        }
    }

    .alert {
        &.alert-danger {
            background: $primaryAlert;
            border: 1px solid $secondaryAlert;
            color: $tertiaryAlert;
        }
    }

    .container-fluid {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 bottom;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: ' ';
            z-index: 0;
            width: 100%;
            height: 100%;
            background-image: url('/images/settings/unsubscribe/background.jpg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
            opacity: 0.1;
        }
    }

    .container {
        padding-top: 100px;
        height: 100vh;
    }

    .unsubscribe {
        background: #eee;
        width: 100vw;
        height: 100vh;
        position: relative;
        h4 {
            font-size: 28px;
            font-weight: 500;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            max-width: 600px;
            margin: 20px auto;
        }
        .face-container {
            width: 169px;
            height: 200px;
            margin: 20px auto 20px auto;
            background-image: url('/images/settings/unsubscribe/unsub-face-sprite.png');
            background-position: center top;
            background-repeat: no-repeat;
        }

        .general-purpose-button {
            padding: 12px 20px 10px 20px;
            font-weight: 400;
            font-size: 16px;
            border-radius: 5px;
            cursor: pointer;
            display: inline-block;
        }

        .unsubscribe-button {
            background: #FFA534;
            color: #ffffff;
            margin-right: 5px;
        }

        .regret-button {
            border: 1px solid #FFA534;
            color: #FFA534;
            margin-left: 5px;
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import LogoLoader from '@/app/shared/components/LogoLoader'

    const NotificationService = require('@/services/notifications/NotificationService')
    const TypeService = require('@/services/notifications/TypeService')

    import MixpanelService from '@/services/mixpanel/MixpanelService'

    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                notification: null,
                state: 'idle',
                hoverButton: null,
                selectedUnsubscriptionLevel: 'type'
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

            typeId() {
                return this.$route.query.id
            },

            face() {
                switch (this.hoverButton) {
                    case 'happy':
                        return '0 0'
                    case 'sad':
                        return '0 -428px'
                    default:
                        return '0 -214px'
                }
            },

            notificationGroup() {
                const notification = this.notification

                if (!notification) {
                    return null
                }

                return notification.notificationGroup
            }
        },

        mounted() {
            if (!this.typeId) {
                return
            }

            this.loadNotificationGroup()
        },

        methods: {
            loadNotificationGroup() {
                TypeService.find(this.typeId, {
                    includes: ['notificationGroup', 'notificationGroup.types']
                }, response => {
                    const data = response.data
                    this.notification = data
                }, error => {
                    this.state = 'error'
                })
            },

            toggleNotification() {
                if (!this.notification) {
                    return
                }

                const notification = this.notification
                const identity = this.identity

                if (notification.important) {
                    this.$swal({
                        text: $t('profile.notificationCenter.swal.areYouCertainSingle'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: $t('profile.notificationCenter.swal.confirm'),
                        cancelButtonText: $t('profile.notificationCenter.swal.decline')
                    }).then(() => {
                        MixpanelService.track('Profile - Notifications - Unsubscribe', {type: notification.slug})

                        this.state = 'unsubscribing'

                        NotificationService.unsubscribe(identity.id, notification.id, {}, () => {
                            this.state = 'unsubscribedType'
                        }, error => {
                            this.state = 'error'
                        })
                    }).catch(() => {})

                    return
                }

                this.state = 'unsubscribing'

                MixpanelService.track('Profile - Notifications - Unsubscribe', {type: notification.slug})

                NotificationService.unsubscribe(identity.id, notification.id, {}, response => {
                    this.state = 'unsubscribedType'
                }, error => {
                    this.state = 'error'
                })
            },

            toggleGroup() {
                if (!this.notificationGroup) {
                    return
                }

                const notificationGroup = this.notificationGroup
                const identity = this.identity

                const match = notificationGroup.types.find(type => {
                    return type.important
                })

                if (match) {
                    this.$swal({
                        text: $t('profile.notificationCenter.swal.areYouCertainSeveral'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: $t('profile.notificationCenter.swal.confirm'),
                        cancelButtonText: $t('profile.notificationCenter.swal.decline')
                    }).then(() => {
                        this.state = 'unsubscribing'

                        notificationGroup.types.forEach(type => {
                            NotificationService.unsubscribe(identity.id, type.id, {}, response => {
                                this.state = 'unsubscribedGroup'
                            }, error => {
                                this.state = 'error'
                            })

                            MixpanelService.track('Profile - Notifications - Unsubscribe', {type: type.slug})
                        })
                    }).catch(() => {})
                    return
                }

                this.state = 'unsubscribing'

                notificationGroup.types.forEach(type => {
                    NotificationService.unsubscribe(identity.id, type.id, {}, response => {
                        this.state = 'unsubscribedGroup'
                    }, error => {
                        this.state = 'error'
                    })

                    MixpanelService.track('Profile - Notifications - Unsubscribe', {type: type.slug})
                })
            },

            cancel() {
                MixpanelService.track('Unsubscribe - Cancel')
                this.$router.push({name: 'home'})
            },

            setHoverButton(button) {
                this.hoverButton = button
            },

            unsubscribe() {
                const userId = this.identity.id

                if (this.state === 'unsubscribing' || this.state === 'error') {
                    return
                }

                if (!this.typeId) {
                    this.$swal({
                        text: $t('profile.notificationCenter.swal.areYouCertainSingle'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: $t('profile.notificationCenter.swal.confirm'),
                        cancelButtonText: $t('profile.notificationCenter.swal.decline')
                    }).then(() => {
                        this.state = 'unsubscribing'

                        NotificationService.unsubscribeAll(userId, {}, (res) => {
                            MixpanelService.track('Unsubscribe - Success')
                            this.state = 'unsubscribedAll'
                        }, () => {
                            this.state = 'error'
                        })
                    }).catch(() => {})

                    return
                }

                switch(this.selectedUnsubscriptionLevel) {
                    case 'type':
                        return this.toggleNotification()

                    case 'group':
                        return this.toggleGroup()
                }
            }
        },

        components: {
            LogoLoader,
            Card
        }
    }
</script>
